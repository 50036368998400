import React from 'react'
import Link from 'gatsby-link'
import logo from './img/Logo-Plessis-Ecohameau.png'; // Tell Webpack this JS file uses this image
import './style.scss'; // Tell Webpack this JS file uses this image


class SiteNavi extends React.Component {
  render() {
    const { location, title } = this.props
    return (
      <nav className="navbar navbar-default my-3">
        <div className="container">
          <Link className="logo text-center" to="/">
            <h1 className="navbar-brand"><img src={logo} alt={title} /></h1>
          </Link>
          <div id="menu" className="navbar-nav-scroll">
            <ul className="navbar-nav bd-navbar-nav flex-row">
              <li
                className={
                  location.pathname === '/qui-sommes-nous/'
                    ? 'nav-item active'
                    : 'nav-item'
                }
              >
                <Link to="/qui-sommes-nous/" className="px-4 nav-link">
                Qui sommes-nous ?
                </Link>
              </li>
              <li
                className={
                  location.pathname === '/le-projet/'
                    ? 'nav-item active'
                    : 'nav-item'
                }
              >
                <Link to="/le-projet/" className="px-4 nav-link">
                Le projet
                </Link>
              </li>
              <li
                className={
                  location.pathname === '/la-maison-commune/'
                    ? 'nav-item active'
                    : 'nav-item'
                }
              >
                <Link to="/la-maison-commune/" className="px-4 nav-link">
                La maison commune
                </Link>
              </li>
              <li
                className={
                  location.pathname === '/actus/'
                    ? 'nav-item active'
                    : 'nav-item'
                }
              >
                <Link to="/actus/" className="px-4 nav-link">
                  Actualités
                </Link>
              </li>
              <li
                className={
                  location.pathname === '/rejoindre/'
                    ? 'nav-item active'
                    : 'nav-item'
                }
              >
                <Link to="/rejoindre/" className="px-4 nav-link">
                  Rejoindre
                </Link>
              </li>
              <li className="nav-item dropdown">
                  <a href="#" className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false"> Contact </a>
                  <div className="dropdown-menu">
                  <Link to="/contact/" className="dropdown-item">Emails de contact</Link>
                  <Link to="/des-questions/" className="dropdown-item">FAQ</Link>
                  </div>
              </li>
          </ul>
          </div>
          {/* <form className="form-inline my-2 my-lg-0 ml-5">
            <a href="https://www.jedonneenligne.org/colibris/DONS/" title="Merci de votre soutien!" className="btn btn-sm btn-danger my-2 my-sm-0"><i className="fa fa-heart"></i> Faire un don</a>
          </form> */}
        </div>
      </nav>
    )
  }
}

export default SiteNavi
